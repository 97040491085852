<template>
    <div style="">
	    <AppTextArea
		    v-model="form.text"
		    label="Note"
		    error-label="Test Note"
		    :rows="20"
	    />
        
    </div>
</template>

<script>

import AppTextArea from "@/components/forms/AppTextArea.vue";

export default {
    name: "EditTestNoteForm",
	components: { AppTextArea},
	props: {
		form: {
		    required: true
	    }
    },
    data() {        
		return {}
    },

}
</script>

<style scoped>

</style>