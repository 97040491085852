<template>
    <div>
        <AppInput
            :name="errorLabel ? errorLabel : name"
            :rules="rules"
            :label="label"
            v-slot="{errors, valid}"
            :row="row"
            :helper-message="helperMessage"
        >
            <v-textarea
                :required="required"
                color="text"
                outlined
                :value="value"
                @input="updateText"
                :error-messages="errors.length > 0 ? [''] : []"
                :placeholder="placeholder"
                :success="success"
                :error="error"
                :type="type"
                @focus="$emit('focus')"
                :name="name"
                :suffix="suffix"
                :prepend-inner-icon="prependInnerIcon"
                @keypress.enter="onEnter"
                :class="{'white-background': whiteBackground}"
                :autocomplete="autocomplete"
                :disabled="disabled"
                :rows="rows"
            >
            </v-textarea>
        </AppInput>
<!--        <validation-provider-->
<!--            v-slot="{ errors, valid }"-->
<!--            :name="errorLabel ? errorLabel : name"-->
<!--            :rules="rules"-->
<!--        >-->
<!--            -->
<!--        <div>-->
<!--            <span style="color: #757575">{{label}}</span> <span v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</span>-->
<!--        </div>-->
<!--        -->
<!--        -->
<!--            <v-text-field-->
<!--                :required="required"-->
<!--                color="text"-->
<!--                outlined-->
<!--                :value="value"-->
<!--                @input="updateText"-->
<!--                :error-messages="errors.length > 0 ? [''] : []"-->
<!--                :placeholder="placeholder"-->
<!--                :success="success"-->
<!--                :error="error"-->
<!--                dense-->
<!--                :type="type"-->
<!--                @focus="$emit('focus')"-->
<!--                :name="name"-->
<!--                :suffix="suffix"-->
<!--                :prepend-inner-icon="prependInnerIcon"-->
<!--            >-->
<!--            </v-text-field>-->
<!--            -->

<!--        </validation-provider>-->
    </div>
    
<!--    <v-row>-->
<!--        <v-col cols="3" style="display: flex; align-items: center">-->
<!--            {{ label }}-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="9" >-->
<!--            <validation-provider-->
<!--                v-slot="{ errors, valid }"-->
<!--                :name="errorLabel ? errorLabel : label"-->
<!--                :rules="rules"-->
<!--            >-->
<!--                <v-text-field-->
<!--                    :required="required"-->
<!--                    color="text"-->
<!--                    outlined-->
<!--                    v-model="value"-->
<!--                    @input="updateText"-->
<!--                    :error-messages="errors.length > 0 ? [''] : []"-->
<!--                    v-mask="mask"-->
<!--                    :placeholder="placeholder"-->
<!--           -->
<!--                    dense-->
<!--                >-->
<!--                </v-text-field>-->
<!--                <div v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</div>-->
<!--                -->
<!--            </validation-provider>-->



<!--        </v-col>-->
<!--    </v-row>-->
</template>

<script>
import { required, digits, email, max, regex, length } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
// import {mask} from 'vue-the-mask'
import {TheMask} from 'vue-the-mask'
import AppInput from "@/components/forms/AppInput";



export default {
    props: {
        value: {
            required: true,
            type: String
        },
        label: {
            required: true,
            type: String
        },
        errorLabel: {
            type: String
        },
        mask: {
            type: String,
            default: 'X'.repeat(10000)
        },
        rules: {

        },
        errorMessages: {
            type: Array
        },
        required: {
            type: Boolean
        },
        placeholder: {
            type: String,
        },
        dense: {
            type: Boolean
        },
        type: {
            type: String,
            default: "text"
        },
        success: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        autocomplete: {
            type: String
        },
        suffix: {
            type: String
        },
        prependInnerIcon: {
            type: String
        },
        row: {
            type: Boolean
        },
        onKeypressEnter: {},
        whiteBackground: {
            type: Boolean,
            default: false
        },
        helperMessage: {},
        disabled: {
            type: Boolean
        },
	    rows: {
			default: 3
	    }
    },
    components: {
        AppInput,
        ValidationObserver,
        ValidationProvider,
        TheMask
    },
    // directives: {mask},
    name: "AppTextArea",
    methods: {
        updateText(e) {
            // console.log(e)
            this.$emit('input', e)
        },
        onEnter() {
            this.$emit('onKeypressEnter')
        }
    },
    computed: {
        adjustedErrorMessages() {
            if (this.errorMessages) {
                return this.errorMessages.length > 0 ? [''] : []
            }

        }
    }

}
</script>

<style >
.white-background {
    background-color: white;
}


</style>